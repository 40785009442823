import "animate.css/animate.min.css";
import "../../styles/homePageStyles/Hero.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import arrowDown from "./../../imgs/arrowDown.png";

export default function Hero() {
  return (
    <section className="mainHero">
      <h1>
        <div className="group">
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={100}
            className="letter"
          >
            N
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={250}
            className="letter"
          >
            I
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={400}
            className="letter"
          >
            C
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={550}
            className="letter"
          >
            O
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={700}
            className="letter"
          >
            L
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={850}
            className="letter"
          >
            A
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={1000}
            className="letter"
          >
            S
          </AnimationOnScroll>
        </div>
        <div className="group">
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={1150}
            className="letter"
          >
            M
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={1300}
            className="letter"
          >
            A
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={1450}
            className="letter"
          >
            R
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={1600}
            className="letter"
          >
            T
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={1750}
            className="letter"
          >
            I
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={1900}
            className="letter"
          >
            N
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={2050}
            className="letter"
          >
            E
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOnce={true}
            duration="1"
            delay={2200}
            className="letter"
          >
            Z
          </AnimationOnScroll>
        </div>
      </h1>

      <h2>
        <div className="group">
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOnce={true}
            duration="1"
            delay={2800}
            offset={5000}
            className="letter"
          >
            FRONT END
          </AnimationOnScroll>{" "}
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOnce={true}
            duration="1"
            delay={2800}
            offset={5000}
            className="letter"
          >
            DEVELOPER
          </AnimationOnScroll>
        </div>
      </h2>
      <AnimationOnScroll
        animateIn="animate__fadeIn"
        animateOnce={true}
        duration="1"
        delay={4000}
        offset={5000}
        className="animationScroll"
      >
        <div className="scrollDown">
          <span>Scroll Down</span>
          <img src={arrowDown} alt="scroll Down"></img>
        </div>
      </AnimationOnScroll>
    </section>
  );
}
